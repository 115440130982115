<template>
  <div
    class="flex-1 min-h-0 flex-col post rounded-lg relative"
    :class="{
      'px-5 py-4 ': !paddingless
    }"
    @dragover="dragover"
  >
    <div class="flex flex-1 min-h-0 flex-col gap-3 mb-2">
      <div v-if="cover != null" class="relative">
        <div class="absolute top-2 right-2">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button type="xlight" class="py-1.5">
                <fw-icon-more class="w-5 h-5 text-gray-500" />
              </fw-button>
            </template>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click.native="removeCover()">
              Remover capa
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-if="backgroundImage"
          class="h-56 rounded-lg bg-cover bg-center border border-gray-100"
          :style="{ backgroundImage: `url('${backgroundImage}')` }"
        ></div>
        <div
          v-else-if="cover && cover.type == 'video'"
          class="flex flex-col gap-5 items-center h-56 rounded-lg bg-gray-200 justify-center"
        >
          <fw-icon-video class="w-16 h-16 text-gray-500" />
        </div>
      </div>
      <div v-if="type != 'comment'">
        <fw-label>Título</fw-label>
        <TextInput v-model="title" :minlength="2" :maxlength="250" :help="`Máximo de 250 caracteres`" autofocus>
        </TextInput>
      </div>
      <div>
        <fw-label>{{
          type == 'comment' && reply ? $t('reply') : type == 'comment' && !reply ? $t('comment') : $t('content')
        }}</fw-label>
        <BlockEditor
          v-if="type == 'post' || type == 'discussion'"
          :value="message"
          :max-height="availableEditorHeight"
          :placeholder-text="$t('write_here')"
          :autofocus="false"
          @input="message = $event"
        />
        <fw-editor v-else v-model="message" code :placeholder-text="$t('write_here')" :limit="postLimit" />
      </div>
      <div v-if="uploadfiles.length">
        <fw-label>Ficheiros</fw-label>
        <RecordFileEntry
          v-for="(file, i) in uploadfiles"
          :key="file.key"
          :can-edit="true"
          :allow-classified="false"
          :file="file"
          :can-download="false"
          class="mb-0.5"
          @save="saveFile(i, $event)"
          @remove="removeFile(i, $event)"
          @download="downloadFile(file)"
        >
          <template #suffix>
            <b-dropdown
              v-if="type == 'post' && cover == null && (file.type == 'image' || file.type == 'video')"
              aria-role="list"
              position="is-bottom-left"
            >
              <template #trigger="{ active }">
                <fw-button type="xlight" class="py-1.5">
                  <fw-icon-more class="w-5 h-5 text-gray-500" />
                </fw-button>
              </template>
              <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click.native="setCover(i)">
                Tornar capa
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </RecordFileEntry>
      </div>
    </div>
    <div class="flex items-center gap-4 text-lg transition-all pt-3 border-t border-gray-100 flex-shrink-0">
      <div v-if="allowFiles">
        <Uploader
          class="cursor-pointer hover:opacity-100 opacity-70 transition-all"
          :is-docked="true"
          :layout="showFullDragZoneUploader ? 'full-dragzone' : 'tiny'"
          reference-id="upload"
          allowed="any"
          :clear-after="true"
          bucket-code="files"
          bucket-type="file"
          input-id="upload"
          @upload="postUploadFile"
          @dragleave="dragleave"
        />
      </div>
      <div class="flex-1 text-gray-500"></div>
      <fw-button class="opacity-50 hover:opacity-80" @click.native="$emit('close')">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        v-if="type != 'comment' && (post == null || post.status == 'draft')"
        class="opacity-70 hover:opacity-80"
        :loading="loading"
        @click.native="save()"
      >
        {{ $t('save') }}
      </fw-button>
      <button
        class="text-white text-sm py-2 px-4 relative font-bold rounded-full"
        :class="{
          'bg-primary': canPublish,
          'bg-gray-300 cursor-not-allowed': !canPublish
        }"
        @click="save(true)"
      >
        <span
          v-if="loading"
          class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          :class="{ 'cursor-not-allowed': loading === true }"
        >
          <fw-icon-loading class="w-5 h-5" />
        </span>
        <div :class="{ 'opacity-0': loading }">
          {{ editing && (type == 'comment' || post.status == 'published') ? $t('save') : $t('publish') }}
        </div>
      </button>
    </div>
  </div>
</template>
<script>
// import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import ServicePosts from '../../services/ServicePosts'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import BlockEditor from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditor'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
export default {
  name: 'BlockEditPost',
  components: {
    Uploader,
    RecordFileEntry,
    BlockEditor,
    TextInput
  },
  props: {
    post: {
      type: Object
    },
    collectionKey: {
      type: String,
      default: null
    },
    parentPostKey: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'post'
    },
    allowFiles: {
      type: Boolean,
      default: true
    },
    metadata: {
      type: Object
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    reply: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: '',
      message: '',
      loading: false,
      showFullDragZoneUploader: false,
      uploadfiles: [],
      cover: null,
      key: null,
      availableEditorHeight: 300
    }
  },
  computed: {
    backgroundImage() {
      return this.cover && this.cover.type == 'image' ? ServiceStorage.getImageViewUrl(this.cover) : null
    },
    postLimit() {
      return this.type != 'post' ? 65000 : null
    },
    editing() {
      return this.post != null
    },
    canPublish() {
      return (
        (this.type != 'comment' && this.message.length > 0 && this.title.length > 0) ||
        (this.type == 'comment' && this.message.length > 0)
      )
    }
  },
  mounted() {
    console.log('BlockEditPost', this.post)
    if (this.post) {
      if (this.post.title) this.title = this.post.title
      if (this.post.message) this.message = this.post.message
      if (this.post.files) this.uploadfiles = this.post.files
      if (this.post.cover) this.cover = this.post.cover
      if (this.post.key) this.key = this.post.key
    }
    this.updateMaxHeight()
  },
  methods: {
    updateMaxHeight() {
      this.$nextTick(() => {
        this.availableEditorHeight = this.calculateContentAvailableHeight()
      })
    },
    calculateContentAvailableHeight() {
      return (
        window.innerHeight -
        360 -
        (this.cover != null ? 225 : 0) -
        (this.type != 'comment' ? 70 : 0) -
        this.uploadfiles.length * 55
      )
    },
    removeCover() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a capa?',
        onConfirm: () => {
          this.cover = null
          this.updateMaxHeight()
        }
      })
    },
    setCover(index) {
      const file = this.uploadfiles[index]
      //remove cover from other files
      this.uploadfiles.splice(index, 1)
      this.cover = file
      this.silentSave()
      this.updateMaxHeight()
    },
    saveFile(i, file) {
      console.log('saveFile', i, file)
      file.filename = file.title
      this.uploadfiles[i] = file
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    removeFile(i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover o ficheiro?',
        onConfirm: () => {
          this.uploadfiles.splice(i, 1)
          this.updateMaxHeight()
        }
      })
    },
    postUploadFile(files) {
      for (let fileEntry of files) {
        const file = fileEntry.response.data.file
        //push if not exists
        if (!this.uploadfiles.find(f => f.key == file.key)) {
          this.uploadfiles.push(file)
        }
      }
      this.showFullDragZoneUploader = false
      this.updateMaxHeight()
    },
    // Handle uploads
    dragover(event) {
      event.preventDefault()
      if (!this.showFullDragZoneUploader) {
        this.showFullDragZoneUploader = true
      }
    },
    dragleave() {
      this.showFullDragZoneUploader = false
    },
    async silentSave(publish = false) {
      try {
        let resquestData = {
          title: this.title.length > 0 ? this.title : null,
          message: this.message,
          type: this.type,
          allow_comments: 'open',
          files: this.uploadfiles,
          cover: this.cover,
          status: publish ? 'published' : 'draft'
        }
        let data
        if (this.type == 'comment') {
          if (this.key) {
            //update comment
            console.log('update comment: ', this.key, resquestData)
            data = await ServicePosts.updatePost(this.key, resquestData, this.metadata)
            this.$buefy.snackbar.open({
              message: `Comentário editado com sucesso.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          } else {
            //create comment
            console.log('create comment: ', this.parentPostKey, resquestData)
            if (this.parentPostKey) {
              data = await ServicePosts.createPostComment(this.parentPostKey, resquestData, this.metadata)
            } else {
              data = await ServicePosts.createPost(resquestData, this.collectionKey, this.metadata)
            }
            data['reply'] = this.reply
            this.$buefy.snackbar.open({
              message: `Comentário criado com sucesso.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          }
          this.$emit('comment-saved', { ...data, files: this.uploadfiles })
        } else {
          if (this.key) {
            //update post
            console.log('update post: ', this.key, resquestData)
            data = await ServicePosts.updatePost(this.key, resquestData, this.metadata)
            console.log('update :>> ', data)
            this.$buefy.snackbar.open({
              message: `Discussão editada com sucesso.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          } else {
            //create post
            console.log('create post: ', resquestData)
            data = await ServicePosts.createPost(resquestData, this.collectionKey, this.metadata)
            console.log('save :>> ', data)
            this.$buefy.snackbar.open({
              message: `Discussão criada com sucesso.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          }
          //update data
          this.cover = data.cover
          if (data.key) {
            this.key = data.key
          }
          data['reply'] = this.reply
          return data
        }
      } finally {
        this.loading = false
      }
    },
    async save(publish = false) {
      console.log('saving....', publish)
      this.loading = true
      let data = await this.silentSave(publish)
      console.log('saved', data)
      data['reply'] = this.reply
      this.$emit('saved', data)
    }
  }
}
</script>

<style>
.post input {
  background-color: #f2f4f4;
  border-radius: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.fw-editor pre {
  background-color: #444444 !important;
  color: #ffffff;
  font-family: monospace;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 0.9em;
}
</style>

<i18n>
{
  "pt": {
    "comment": "Comentário",
    "content": "Conteúdo",
    "write_here": "Escreva aqui...",
    "save": "Guardar",
    "publish": "Publicar",
    "cancel": "Cancelar",
    "reply": "Responder"
  },
  "en": {
    "comment": "Comment",
    "content": "Content",
    "write_here": "Write here...",
    "save": "Save",
    "publish": "Publish",
    "cancel": "Cancel",
    "reply": "Reply"
  }
}
</i18n>
